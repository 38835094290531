import request from '@/utils/request'

// 查询配置管理列表
export function listWorkOptions(query) {
  return request({
    url: '/work/WorkOptions/list',
    method: 'get',
    params: query
  })
}

// 查询配置管理详细
export function getWorkOptions(id) {
  return request({
    url: '/work/WorkOptions/' + id,
    method: 'get'
  })
}

// 新增配置管理
export function addWorkOptions(data) {
  return request({
    url: '/work/WorkOptions',
    method: 'post',
    data: data
  })
}

// 修改配置管理
export function updateWorkOptions(data) {
  return request({
    url: '/work/WorkOptions/update',
    method: 'post',
    data: data
  })
}

// 删除配置管理
export function delWorkOptions(id) {
  return request({
    url: '/work/WorkOptions/remove/' + id,
    method: 'get'
  })
}

// 导出配置管理
export function exportWorkOptions(query) {
  return request({
    url: '/work/WorkOptions/export',
    method: 'get',
    params: query
  })
}